import { getPublicPropertiesStore } from '../../../../entry/publicMethods/publicProperties/registerPublicProperties';
import { ExpressedConsent } from '../../../../shared/types/consent';
import createState from '../../../../shared/utils/createState';

export type HTMLCustomDataStorage = {
  get: (key: string) => string | null;
  set: (key: string, val: string | null) => void;
};

export const getHTMLCustomDataStorage = (): HTMLCustomDataStorage => {
  const publicPropertiesStore = getPublicPropertiesStore();
  const consent = publicPropertiesStore.getState()['visitor:cookiesConsent'];
  const getSessionItem = (key: string) => sessionStorage.getItem(key);
  const setSessionItem = (key: string, val: string | null) =>
    sessionStorage.setItem(key, String(val));

  const [getState, setState] = createState<Record<string, string>>({});
  const getInMemory = (key: string) => getState()[key] || null;
  const setInMemory = (key: string, val: string | null) =>
    setState({ ...getState(), [key]: String(val) });

  const onVisitorCookieConsent = (expressedConsent: ExpressedConsent) => {
    if (expressedConsent !== true) return;
    Object.keys(getState()).forEach((key) => {
      setSessionItem(key, getState()[key]);
    });
    setState({});
    publicPropertiesStore.off('visitor:cookiesConsent', onVisitorCookieConsent);
  };
  publicPropertiesStore.on('visitor:cookiesConsent', onVisitorCookieConsent);

  return {
    get: (key) => (consent ? getSessionItem(key) : getInMemory(key)),
    set: (key, val) =>
      consent ? setSessionItem(key, val) : setInMemory(key, val),
  };
};
