/*_________________________________________*/
import {
  DataType,
  HTMLCustomDataConfig,
  CustomDataValues,
  CommonConfig,
} from '../../../../entry/visitorContextConfig/types';
import { TriggerTargetingFn } from '../../../types';
import { getCustomDataDifference } from '../utils';
import {
  HTMLCustomDataStorage,
  getHTMLCustomDataStorage,
} from './HTMLCustomDataStorage';

const PREVIOUS_PAGE_PREFIX = 'prev_';

const parseString = (
  value: string | null,
  dataType: DataType,
): { value: string | number | boolean | null; warnings?: string[] } => {
  if (value === null) return { value };
  switch (dataType) {
    case 'STRING':
      if (value.length < 1000) {
        return { value };
      }
      return {
        value: value.substring(0, 1000),
        warnings: ['a string custom data was over 1000 chars long'],
      };
    case 'INTEGER':
    case 'FLOAT':
      return { value: Number(value) };
    case 'BOOLEAN':
      return { value: value === 'true' };
    default:
      return { value: null };
  }
};

const parseAndUpdateHTMLCustomData = (
  config: HTMLCustomDataConfig[number],
  value: string | null,
  customDataStorage: HTMLCustomDataStorage,
): { value: string | number | boolean | null; warnings?: string[] } => {
  if (!value) return { value: null };
  const { aggregationType, dataType, expectedValue, name } = config;
  const { value: parsedValue, warnings } = parseString(value, dataType);
  const lastStoredValue = customDataStorage.get(name);
  switch (aggregationType) {
    case 'CURRENT':
      return { value: parsedValue, warnings };
    case 'COUNT':
      const shouldIncrement =
        parsedValue === parseString(expectedValue!, dataType).value;
      const newCountValue = shouldIncrement
        ? Number(lastStoredValue) + 1
        : Number(lastStoredValue);
      customDataStorage.set(name, String(newCountValue));
      return { value: newCountValue, warnings };
    case 'PREVIOUS_PAGE':
      if (config.name.includes(PREVIOUS_PAGE_PREFIX)) {
        customDataStorage.set(name, String(parsedValue));
        return { value: parsedValue, warnings };
      }
      return {
        value: parseString(
          customDataStorage.get(`${PREVIOUS_PAGE_PREFIX}${name}`),
          dataType,
        ).value,
      };
    case 'MIN':
      if (!lastStoredValue) {
        customDataStorage.set(name, String(parsedValue));
        return { value: parsedValue, warnings };
      }
      const newMinValue = Math.min(
        Number(parseString(lastStoredValue, dataType).value),
        Number(parsedValue),
      );
      customDataStorage.set(name, String(newMinValue));
      return { value: newMinValue, warnings };
    case 'MAX':
      if (!lastStoredValue) {
        customDataStorage.set(name, String(parsedValue));
        return { value: parsedValue, warnings };
      }
      const newMaxValue = Math.max(
        Number(parseString(lastStoredValue, dataType).value),
        Number(parsedValue),
      );
      customDataStorage.set(name, String(newMaxValue));
      return { value: newMaxValue, warnings };
  }
};

const getHTMLCustomDataValues = (
  htmlCustomDataConfig: HTMLCustomDataConfig,
): Record<string, string> =>
  htmlCustomDataConfig.reduce((acc, { name, selector }) => {
    try {
      const element: Element | null = document.querySelector(selector);
      const value = element
        ? element?.textContent || (element as HTMLInputElement)?.value
        : null;
      return value === null ? acc : { ...acc, [name]: value };
    } catch {
      return acc;
    }
  }, {});

const watchHTMLCustomDataChange = async (
  htmlCustomDataConfig: HTMLCustomDataConfig,
  runTargetingWithUpdatedCustomDataValues: (
    htmlCustomDataValues: CustomDataValues,
    warnings: string[],
  ) => Promise<{ hasLaunchedFullMode: boolean }>,
  lastCustomDataValues: Record<string, string> = {},
  customDataStorage: HTMLCustomDataStorage,
): Promise<CustomDataValues> => {
  const htmlCustomDataValues: Record<string, string> =
    getHTMLCustomDataValues(htmlCustomDataConfig);

  const shouldRerunTargeting =
    JSON.stringify(lastCustomDataValues) !==
    JSON.stringify(htmlCustomDataValues);

  const updatedHTMLCustomData = getCustomDataDifference(
    lastCustomDataValues,
    htmlCustomDataValues,
  );

  const warnings: string[] = [];
  const updatedParsedValues = Object.keys(updatedHTMLCustomData).reduce(
    (acc, key) => {
      const { value: updatedValue, warnings: newWarnings = [] } =
        parseAndUpdateHTMLCustomData(
          htmlCustomDataConfig.find((c) => c.name === key)!,
          updatedHTMLCustomData[key],
          customDataStorage,
        );
      warnings.push(...newWarnings);
      return updatedValue === null ? acc : { ...acc, [key]: updatedValue };
    },

    {},
  );

  const { hasLaunchedFullMode } = shouldRerunTargeting
    ? await runTargetingWithUpdatedCustomDataValues(
        updatedParsedValues,
        warnings,
      )
    : { hasLaunchedFullMode: false };

  if (!hasLaunchedFullMode) {
    setTimeout(
      () =>
        watchHTMLCustomDataChange(
          htmlCustomDataConfig,
          runTargetingWithUpdatedCustomDataValues,
          htmlCustomDataValues,
          customDataStorage,
        ),
      2000,
    );
  }

  return htmlCustomDataValues;
};

export const registerCustomData = ({
  htmlCustomDataConfig,
  sid,
  setPrevPageCustomDataFn,
  triggerTargeting,
  isAuthenticationEnabled,
  commonConfig,
}: {
  htmlCustomDataConfig: HTMLCustomDataConfig;
  sid: number;
  setPrevPageCustomDataFn: (fn: () => CustomDataValues) => void;
  triggerTargeting: TriggerTargetingFn;
  isAuthenticationEnabled: boolean;
  commonConfig: CommonConfig;
}): Promise<CustomDataValues> => {
  const customDataStorage = getHTMLCustomDataStorage();

  const runTargetingWithUpdatedCustomDataValues = (
    htmlCustomDataValues: CustomDataValues,
    warnings: string[] = [],
  ) =>
    triggerTargeting({
      customData: htmlCustomDataValues,
      registerNavigation: false,
      sid,
      warnings,
      isAuthenticationEnabled,
      commonConfig,
    });

  const lastCustomDataValues: Record<string, string> = {};

  const nextCustomDataValuesPromise = watchHTMLCustomDataChange(
    htmlCustomDataConfig,
    runTargetingWithUpdatedCustomDataValues,
    lastCustomDataValues,
    customDataStorage,
  );

  const prevPageHtmlCustomDataConfig = htmlCustomDataConfig
    .filter((config) => config.aggregationType === 'PREVIOUS_PAGE')
    .map((config) => ({
      ...config,
      name: `${PREVIOUS_PAGE_PREFIX}${config.name}`,
    }));

  const updatePrevPageCustomData = () => {
    const prevPageValues = getHTMLCustomDataValues(
      prevPageHtmlCustomDataConfig,
    );
    return prevPageHtmlCustomDataConfig.reduce((acc, config) => {
      const { value: updatedCustomData } = parseAndUpdateHTMLCustomData(
        config,
        prevPageValues[config.name],
        customDataStorage,
      );
      return updatedCustomData === null
        ? acc
        : {
            ...acc,
            [config.name.replace(PREVIOUS_PAGE_PREFIX, '')]: updatedCustomData,
          };
    }, {});
  };
  updatePrevPageCustomData();
  setPrevPageCustomDataFn(updatePrevPageCustomData);

  return nextCustomDataValuesPromise;
};
